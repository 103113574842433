import { Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Restore } from "Packer/components"

import ModalOrderView from "Packer/Pages/AttachCurierV2/ModalOrderView"
import { api } from "services"
import ScannedProductInfo from "./ScannedProductInfo"

import "./styles.scss"
import "./packer-modal.scss"

function PackerLayout() {
  useEffect(() => {
    api({
      baseURL: "",
      url: "/package-containers.json",
      cache: {
        ttl: 0,
      },
    }).then((res) => {
      if (res.data) {
        try {
          sessionStorage.setItem("containers", JSON.stringify(res.data))
        } catch (err) {
          //
        }
      }
    })
  }, [])

  return (
    <div className="packer packer-layout">
      <div className="packer-wrapper">
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
      <Suspense>
        <ScannedProductInfo />
      </Suspense>
      <Restore />
      <ModalOrderView />
    </div>
  )
}

export default PackerLayout
