import { lazy } from "react"
import { helpers } from "services"

const CheckCard = helpers.withSuspense(lazy(() => import("./CheckCard")))
const Messages = helpers.withSuspense(lazy(() => import("./Messages")))
const Restore = helpers.withSuspense(lazy(() => import("./Restore")))
const Checkbox = helpers.withSuspense(lazy(() => import("./Checkbox")))
const HistoryCheckCard = helpers.withSuspense(lazy(() => import("./HistoryCheckCard")))
const PrintCheck = helpers.withSuspense(lazy(() => import("./PrintCheck")))

export { CheckCard, Messages, Restore, Checkbox, HistoryCheckCard, PrintCheck }
