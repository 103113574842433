/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { Icons, Modal } from "components"
import { find, get } from "lodash"

import "./styles.scss"
import { Emitter, helpers, time } from "services"
import { useEffect, useState } from "react"
import { useRequest } from "hooks"

function ModalOrderView() {
  const [id, setId] = useState()

  const order = useRequest({
    url: `packer/orders/${id}`,
    enabled: !!id,
  })

  const {
    order_items = [],
    operator_id,
    client_full_name,
    created_at,
    full_address,
    status_label,
    price,
    packed_at,
  } = get(order.data, "data") || {}

  const total = order_items.reduce((pv, cr) => pv + get(cr, "quantity", 0), 0)
  const handleClose = () => setId()

  useEffect(() => {
    Emitter.on("order-view:open", ({ itemId }) => {
      if (!window.location.pathname.includes("attach-curier")) {
        setId(itemId)
      }
    })
  }, [])

  return (
    <Modal
      isOpen={!!id}
      handleClose={handleClose}
      title={
        <div>
          <h2>{full_address}</h2>
        </div>
      }
      headerLeftContent={<p className="order-count">{total}</p>}
      className="packer__modal-print info"
      hasClose={false}
    >
      <div style={{ padding: "0.625vw" }} onClick={handleClose} className="close-icon">
        <Icons.XIcon style={{ cursor: "pointer" }} />
      </div>
      <table>
        <tbody>
          {order_items.map((item, i) => {
            const { product_title, quantity, product, product_item_id } = item
            const postion = find(get(product, "items"), { id: product_item_id })
            const positionName = get(postion, "name")
            return (
              <tr key={i}>
                <td>
                  <p className="order__product">
                    {product_title} {positionName ? `(${positionName})` : ""}{" "}
                  </p>
                </td>
                <td>
                  <p className="order__item-count">{quantity}</p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <ul>
        <li>
          <p className="key">Xaridor</p>
          <p className="value">{client_full_name}</p>
        </li>
        <li>
          <p className="key">Summasi</p>
          <p className="value">{helpers.toReadable(get(price, "total"))}</p>
        </li>
        <li>
          <p className="key">Oparator</p>
          <p className="value id">#{operator_id}</p>
        </li>
        <li>
          <p className="key">Qadoqlangan vaqt</p>
          <p className="value">{packed_at ? time.to(packed_at) : null}</p>
        </li>
        <li>
          <p className="key">Vaqti</p>
          <p className="value">{created_at ? time.to(created_at) : null}</p>
        </li>
        <li>
          <p className="key">Statusi</p>
          <div className="status">
            <span />
            <p>{status_label}</p>
          </div>
        </li>
      </ul>
    </Modal>
  )
}

export default ModalOrderView
