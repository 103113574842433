const { lazy } = require("react")

const Pages = {
  Profile: lazy(() => import("Packer/Pages/Profile")),
  Signin: lazy(() => import("Packer/Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Packer/Pages/Auth/RestorePassword")),

  PackerAttachCurierV2: lazy(() => import("Packer/Pages/AttachCurierV2")),
  IncomeFromStorage: lazy(() => import("Packer/Pages/Disposal/List")),

  PackerLists: lazy(() => import("Packer/Pages/Lists")),
  PackerUpdate: lazy(() => import("Packer/Pages/Lists/Update")),

  Containers: lazy(() => import("Packer/Pages/PackageContainers")),

  Store: lazy(() => import("Packer/Pages/Store")),
  IncomeOrder: lazy(() => import("Packer/Pages/IncomeOrder")),

  PickWarehouse: lazy(() => import("Packer/Pages/PickWarehouse")),

  AccountTransactions: lazy(() => import("Packer/Pages/AcountTransactions")),

  OrderCreate: lazy(() => import("Packer/Pages/Order/Create")),
  OrderPrint: lazy(() => import("Packer/Pages/PrintOrders")),
  StorePrintedOrders: lazy(() => import("Packer/Pages/Store/PrintedOrders")),
}

export default Pages
