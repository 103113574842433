import { get, isObject } from "lodash"
import { useQuery } from "@tanstack/react-query"
import { query as q } from "services"
import qs from "qs"

function useRequest({
  url,
  urlSearchParams,
  method = "get",
  select = (data) => data.data,
  onSuccess = () => {},
  onError = () => {},
  queryKey,
  dataKey = "data",
  metaKey = "meta",
  route = "",
  ttl = 0,
  searchSaveKey,
  ...attr
}) {
  const { search = "" } = qs.parse(
    window.location.search.replace("?", "") || sessionStorage.getItem(searchSaveKey),
  )
  const params = {
    ...urlSearchParams,
  }
  if (search.length > 1) params.search = search

  const option = {
    ...attr,
    queryKey: queryKey ?? (isObject(urlSearchParams) ? [url, urlSearchParams, search] : [url]),
    queryFn: async (context) => {
      const res = await q.queryFn({
        context,
        url,
        urlSearchParams: params,
        dataKey,
        metaKey,
        route,
        method,
        ttl,
        onSuccess,
        onError,
      })

      if (get(res, "status") >= 200 && get(res, "status") < 300) onSuccess(res.data)
      else onError(res)

      return res
    },

    select,
  }

  return useQuery(option)
}

export default useRequest
