import { get } from "lodash"
import notification from "services/notification"

function errorHandler(err) {
  if (get(err, "response.status") === 401) {
    window.localStorage.removeItem("warehouse_id")
    window.localStorage.removeItem("token")
    window.localStorage.removeItem("user")

    window.location.pathname = "/login"
  }
  if (get(err, "response.status") >= 400 && get(err, "response.status") < 500) {
    const message = get(err, "response.data.message") || get(err, "response.data.data.message")

    if (message) notification.error(message)
    else notification.error()
  }
  if (get(err, "response.status") >= 500) {
    notification.error("Serverda xatolik")
  }

  return null
}

export default errorHandler
